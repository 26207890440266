.carousel {
    .control-dots {
        .dot {
            width: 17px;
            height: 17px;
        }
    }
}

.react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
        margin: 10px;
        button {
            width: 17px;
            height: 17px;
        }
    }
    .react-multi-carousel-dot--active {
        > button {
            background-color: white;
        }
    }
}